@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  font-family: 'Roboto', sans-serif !important;
}

.material-icons {
  float: left;
}

/* HOME  */
.home .my-nav {
  width: 100%;
  z-index: 100;
}

.home .left {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home .left h1 {
  font-weight: 700;
  font-size: 50px;
  color: #ff9300;
}

.home .left p {
  font-size: 18.5px;
}

.home .left .btn-deep-orange {
  background-color: #ff9300;
}

/* TALK TO ASTRO  */
.talk-to-astro {
  background: #f7f7f7;
}

.chip {
  display: inline-block;
  height: 52px;
  width: 200px;
  padding: 0 12px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  background-color: #f7f7f7;
  border-radius: 26px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.custome-nav-link.active {
  background-color: black !important;
  color: #fff !important;
}

.custome-nav-link {
  border: 1px solid transparent;
  background-color: #fff;
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
  border-radius: 25px !important;
  font-size: 12px;
  color: #78849e;
  padding: 5px 30px !important;
  margin: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custome-nav-link:hover {
  border: 1px solid transparent;
  background-color: #fff;
  border-radius: 25px;
  font-size: 12px;
  color: #000;
  padding: 5px 30px !important;
}

#partitioned {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(
    to left,
    black 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 100%;
}

.chat {
  width: 100%;
  float: left;
  background: #ffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #434651;
  /* border-bottom: 1px solid #43465120; */
  margin-bottom: 1rem;
}

.wallet-link:hover {
  cursor: pointer;
}

.chat .chat-header {
  /* border-bottom: 2px solid white; */
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-bottom: 14px; */
}

.chat .chat-header .chat-about {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.chat .chat-header .chat-num-messages {
  color: #92959e;
  padding-right: 10px;
}

.chat .chat-header .chat-num-messages span {
  margin: 0 5px;
  color: #52575d;
}

.chat .chat-header .fa-star {
  color: orange;
  font-size: 20px;
  font-size: 10px;
  margin-right: 4px;
}

.chat .chat-history {
  padding: 30px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: scroll;
  height: 575px;
}

.form-check-label {
  margin-left: 20px;
}

.btn-rounded {
  border-radius: 10em !important;
}

.btn-width {
  width: 85%;
  padding: 12px;
  font-size: 16px;
  letter-spacing: 1px;
}

.otp-input-text,
.form-control.otp-input-text {
  width: 35px;
  float: left;
  border-radius: 0px;
  border-left: 0px;
}

.form-control.otp-input-text.first {
  border-left: 1px solid#ced4da;
  border-top-left-radius: 10em;
  border-bottom-left-radius: 10em;
}

.form-control.otp-input-text.last {
  border-top-right-radius: 10em;
  border-bottom-right-radius: 10em;
}

#burgerMenu {
  position: absolute;
  width: 300px;
  margin: 0 0 0 300px;
  padding: 50px;
  padding-top: 0px;
  z-index: 1;
  background: #ededed;
  list-style-type: none;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#burgerMenu li {
  padding: 10px 0;
  font-size: 22px;
}

.userButtonStyle {
  box-shadow: none;
  width: 45px !important;
  height: 45px !important;
  border-radius: 50px !important;
  text-align: center !important;
  padding: 0px !important;
  color: #fff;
  background-color: #ff9300 !important;
  padding-top: 8px;
  margin-top: 5px;
}

.dropdown-toggle::after {
  content: none;
}

.messages-container {
  min-height: 400px;
  margin-bottom: 10px;
  clear: both;
}

.messages-container > .chat > .messages-list {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.messages-container > .chat > .messages-list > .message-item {
  max-width: 75%;
  width: auto;
  overflow: hidden;
  margin: 5px 0;
}

.messages-container > .chat > .messages-list > .message-item > .chat-header {
  padding: 0px;
  align-self: flex-end;
}

.messages-container
  > .chat
  > .messages-list
  > .message-item
  > .chat-header
  > .message_body {
  width: min(90vw, 100%);
  word-break: break-all;
  padding: 10px;
  border-radius: 5px;
}

.messages-container > .chat > .messages-list > .admin-message {
  align-self: center;
  display: flex;
  flex-direction: column;
  background-color: #ff930014;
  font-size: small;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 100px;
}

.messages-container > .chat > .messages-list > .my-message {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}

.messages-container > .chat > .messages-list > .received-message {
  align-self: flex-start;
}

.messages-container > .chat > .messages-list > .my-message .message_body {
  background-color: #f7f7f7;
}

.messages-container > .chat > .messages-list > .received-message .message_body {
  background-color: #ff930014;
}

.received-message .chat-about {
  justify-content: left;
}

.my-message .chat-about {
  justify-content: right;
}

.messages-container-input {
  display: flex;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.messages-container-input > textarea {
  border: 0px;
  margin: 0 10px;
  width: min(60vw, 700px);
}

/* .messages-container-input > div{
  
} */

.messages-container > .chat > .chat-header {
  border-bottom: 2px solid white;
  font-size: 12px;
}

.messages-container > .chat {
  width: 100%;
  float: left;
  background: #ffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #434651;
}

.star-rating {
  font-family: 'FontAwesome';
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.star-rating > fieldset {
  border: none;
  display: inline-block;
}

.star-rating > fieldset:not(:checked) > input {
  position: absolute;
  top: -9999px;
  clip: rect(0, 0, 0, 0);
}

.star-rating > fieldset:not(:checked) > label {
  float: right;
  width: 1em;
  padding: 0 0.05em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 200%;
  color: #ddd;
}

.star-rating > fieldset:not(:checked) > label:hover,
.star-rating > fieldset:not(:checked) > label:hover ~ label {
  color: #f57c00;
  text-shadow: 0 0 3px #f57c00;
}

.star-rating > fieldset > label:active {
  position: relative;
  top: 2px;
}

.handler-grid .swiper-container {
  width: 100%;
  height: 100%;
}

.handler-grid .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: contents;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.handler-grid .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.handler-grid .row .col-sm-6 {
  margin: unset !important;
  margin-bottom: 20px !important;
}

.handler-grid .row > .cardTopMargin > .card {
  margin-top: 15px;
}

.handler-grid .row > .cardPaddingLeft {
  padding-right: 0px;
}

.modal-body > .row > .col-6 > p {
  font-size: 14px;
}

.modal-body > .row > .col-6 > p > .material-icons {
  float: revert;
}

.navbar-dark > .navbar-brand > .nav-link {
  padding: 0rem 1rem 0rem 0rem;
}

.dropdown-menu {
  padding: 0px !important;
}

/* .dropdown-item {
  padding: 0.25rem 0.5rem !important;
} */

.input_container {
  position: relative;
  margin-bottom: 20px;
}

.input_container > .input_error {
  position: absolute;
  top: 100%;
  left: 13px;
  color: red;
  font-size: 12px;
}

.input_container > .input_error::after {
  content: '*';
}

select,
option {
  width: 100%;
  border-color: #ced4da;
}

.rating-color {
  color: #ff9300 !important;
}

.rating-muted {
  color: #78849e80 !important;
}

.disable-scrollbars::-webkit-scrollbar {
  background: transparent;
  /* Chrome/Safari/Webkit */
  width: 0px;
}

.disable-scrollbars {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
  /* height: 380px; */
}

input[type='number'].number-hidden-spin::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.message-text {
  border: none;
  outline: none;
  max-width: 80%;
}

.pagination_links > a {
  color: inherit;
  margin: 0 5px;
  font-size: 12px;
}

.text {
  margin: 20px 30px;
}

.otp-input-text,
.form-control.otp-input-text {
  width: 50px;
  padding: 5px;
}

.filterimg_container {
  position: relative;
}

.filterimg_container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  background-color: #f57c00;
  border-radius: 50%;
  z-index: 1;
}

.feedback_text {
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.muted-color {
  color: #bbc1ce !important;
}

.astro_card_image_container {
  position: relative;
  width: 100px;
  max-width: 100px;
  height: 100px;
  max-height: 100px;
  outline: 2px solid #ff0000 !important;
}

.astro_card_image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #78849e;
}

.astro_card_image_container > .astro_card_image_span {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #000;
  color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
  text-align: left !important;
}

.input_field_style {
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 10px !important;
  border-radius: 40px !important;
  color: #000;
}

.progress_bar {
  width: 100vw;
  height: 100vh;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-outline .form-control ~ .form-notch {
  display: none !important;
}

.form-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 4px 16px #455b6314; */
  border-radius: 26px;
  opacity: 1;
}

.form-outline .form-control:disabled {
  background-color: #fff !important;
}

.form-outline .form-control.form-control-lg ~ .form-label {
  color: #78849ecc;
  font-weight: 600;
  padding-top: 1.2rem !important;
  padding-left: 1.5rem !important;
}

.form-outline .form-control.form-control-lg {
  padding-left: 2rem !important;
  padding-top: 1.5rem !important;
  font-weight: 700;
}

.input_container.select .custom-select {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: rgb(0 0 0 / 6%) 0px 0px 10px;
  border-radius: 40px;
  opacity: 1;
  outline: none;
  border: none;
  padding-left: 2rem !important;
  padding-top: 1.5rem !important;
  font-weight: 700;
  color: #000;
}

.input_container.select label {
  position: absolute;
  color: #78849ecc;
  font-weight: 600;
  padding-left: 2.1rem !important;
  padding-top: 10px;
  font-size: 13.5px;
  letter-spacing: -0.1px;
}

.form-outline .form-control.form-control-lg:focus ~ .form-label,
.form-outline .form-control.form-control-lg.active ~ .form-label {
  color: #78849e;
  transform: translateY(-0.5rem) translateY(0.1rem) scale(0.8) !important;
}

.profilenav {
  padding-right: 0px !important;
}

.profilenav a {
  text-transform: uppercase;
  text-align: left;
  color: #78849e;
  font-size: 14px !important;
}

.profilenav a.active {
  border-radius: 0px 15px 15px 0px !important;
  width: 98% !important;
}

.profileimg {
  border-radius: 50% !important;
}

.input-group.gapgroup {
  padding: 0 !important;
  margin: 10px 0;
}

@media screen and (max-width: 460px) {
  .profilenav .card-body {
    padding: 10px 0 !important;
  }

  .profilenav .card .row {
    --mdb-gutter-x: 0px;
  }

  .profilenav .card {
    height: 150px !important;
    margin: 10px 0;
    padding: 5px 10px !important;
  }

  .profilenav .col-sm-4 {
    flex-basis: 33.33%;
  }

  .profile-nav-btn {
    border-radius: 20px !important;
  }

  .profileinfo .card {
    margin: 0 0 0 5px;
    padding: 10px !important;
    text-align: center;
  }

  .profileinfo h2 {
    font-size: 25px !important;
    margin: 30px 0 !important;
    letter-spacing: 1px !important;
  }

  .wallet-table th,
  .wallet-table td {
    white-space: pre;
  }

  .profileinfo h2 {
    font-size: 25px !important;
    margin: 30px 0 !important;
    letter-spacing: 1px !important;
  }

  .wallet-table {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
  }

  .wallet-table th {
    background: rgb(232, 232, 232);
  }
}

.calendar-novalue .react-date-picker__inputGroup__divider {
  display: none !important;
}

.radio-buttons .form-check-input:checked {
  border-color: #ff9300 !important;
}

.radio-buttons .form-check-input[type='radio']:checked:after {
  background-color: #ff9300 !important;
  border-color: #ff9300 !important;
}

.custom-switch .form-check-input[type='checkbox']:checked {
  background-color: #34c759 !important;
}

.custom-switch .form-switch .form-check-input:checked[type='checkbox']:after {
  background-color: #34c759 !important;
}

.phone.form-control:disabled {
  background-color: #d9e0ef !important;
}

.table-with-pagination {
  height: 403px;
  max-height: 403px !important;
}

.full-pagination {
  height: 453px;
  max-height: 453px !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  margin: 0 !important;
}

.feedback_border {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #455b6314;
  border-radius: 10px;
  opacity: 1;
}

.chat_container {
  padding: 0 10rem;
}

@media screen and (max-width: 800px) {
  .chat_container {
    padding: 0 2rem;
  }
}

/* .nav_menu_text{
  color:black;
} */

.contact {
  display: flex;
  align-items: center;
}

.contact .input-group {
  width: 70%;
}

.contact .send-btn {
  position: absolute;
  height: 35px;
  right: 5px;
  top: 5px;
  outline: none;
  border: none;
  color: #fff;
  background: #ff9300;
  border-radius: 20px !important;
  z-index: 10;
  padding: 4px 16px;
}

.modal.show {
  height: 100vh;
  overflow-y: hidden;
}

.modal.show .modal-dialog {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal.show .modal-dialog .cancel-btn,
.modal.show .modal-dialog .apply-btn {
  border-radius: 20px;
  font-weight: 600;
  border: 1px solid #ff9300;
}

.modal.show .modal-dialog .cancel-btn {
  background-color: #fff;
  color: #ff9300;
}

.modal.show .modal-dialog .apply-btn {
  background-color: #ff9300;
}

.swiper-button-next {
  border-radius: 50%;
  background: #ff9300;
  width: 50px !important;
  height: 50px !important;
  background-image: url(./components/img/right-icon.svg);
  background-repeat: no-repeat;
  background-size: 45% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background: #ff9300;
  width: 50px !important;
  height: 50px !important;
  background-image: url(./components/img/left-icon.svg);
  background-repeat: no-repeat;
  background-size: 45% auto;
  background-position: center;
  border-radius: 50%;
}

.swiper-button-prev::after {
  display: none;
}

/* .swiper-button-prev,
.swiper-button-next {
  background: #ff9300;
  width: 50px !important;
  height: 50px !important;
  padding: 12.5px 10px;
  border-radius: 50%;

  display: none;
}
.swiper-button-prev.show,
.swiper-button-next.show {
  display: block;
} */

/* .swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
} */

.mySwiper.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: painted !important;
}

.focusnone {
  outline: none;
  color: #78849e;
  font-size: 13px;
  font-weight: 600;
}

.resettext {
  font-size: 13px;
  /* letter-spacing: 1px; */
  font-weight: 600;
  color: #ff9300;
}

.language-txt {
  padding: 10px 0;
}

.filter_box {
  padding: 0 20px;
}

.filteritem .badge-dark {
  background: #000;
  font-weight: 100 !important;
}

.astrologer-form h1 {
  text-transform: none !important;
  font-size: 35px !important;
  letter-spacing: 1px;
  line-height: 45px;
}

.astrologer-form p {
  color: #9da6b9;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.swiper-slide {
  margin-right: 0px !important;
  /* width: 250px !important; */
  /* margin: 0 15px; */
}

.cardbox {
  width: 240px !important;
}

.astrologer-form .input-group {
  padding: 0 !important;
  margin: 20px 0;
}

.astrologer-form .mobileimgdisplay {
  display: none;
}

.astropage button .dropdown {
  font-size: 12px;
}

.pricebtn {
  width: 100%;
}

@media (max-width: 550px) {
  .socio-cont {
    width: 100%;
  }

  .sm-ft-10 {
    font-size: 10px !important;
  }

  .astropage h1 {
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .astropage button {
    padding: 5px;
    white-space: pre;
  }

  .slider-astrologer h1 {
    font-size: 20px !important;
    font-weight: 500 !important;
    margin: 5px !important;
  }

  .slider-astrologer {
    margin-bottom: 15px;
  }

  .slider-card-child {
    width: 100% !important;
    max-width: 100% !important;
  }

  .slider-card-child .card {
    width: 100%;
  }

  .slider-card-child img {
    float: left;
  }

  .slider-card-child .profile-content {
    transform: translateX(10px);
    text-align: left;
  }

  .slider-card-child .timetext {
    white-space: pre;
    font-size: 12px;
  }

  .slider-card-child .socialicon {
    text-align: right;
  }

  .astropage button {
    width: unset !important;
    padding: 8px;
    margin: 0;
  }

  .resettext {
    font-size: 10px !important;
  }

  .astrologer-form {
    text-align: center;
    padding-bottom: 30px;
  }

  .astrologer-form h1 {
    font-size: 22px !important;
    font-weight: 600 !important;
    margin: 20px 5px !important;
    line-height: 30px;
  }

  .rowReverse {
    flex-flow: wrap-reverse !important;
  }

  .rowReverse .input-group {
    width: 100%;
  }

  .contact .send-btn {
    position: absolute;
    margin: 15px 0px;
    top: 100%;
    right: 0;
    text-align: right;
  }

  /* .contact input {
    width: 85% !important;
  } */

  .astropage button img {
    width: 18px;
    height: 20px;
    padding: 0;
  }

  .astropage button .dropdown {
    font-size: 15px;
  }

  .astrologer-form .mobileimgdisplay {
    display: block;
  }

  .astrologer-form .mobileimg2 {
    display: none;
  }

  .astrologer-form .addimg {
    margin: 10px 0;
  }

  .astrologer-form .addimg img {
    width: 100% !important;
  }

  .astrologer-form .addimg .img1 {
    height: 57px;
  }

  .astrologer-form .addimg .img2 {
    margin-left: 0px !important;
  }

  .astropage button.pricebtn {
    width: 95% !important;
    padding: 8px 15px !important;
  }
}

@media (max-width: 765px) {
  .pricebtn,
  .reset-filter-btn {
    padding: 6px 10px;
  }
}

.col-xxl-15 {
  width: 20%;
  float: left;
}

@media (max-width: 1350px) {
  .col-xxl-15 {
    width: 25%;
    float: left;
  }
}

@media (max-width: 992px) {
  .col-xxl-15 {
    width: 33.33%;
    float: left;
  }
}

@media (max-width: 768px) {
  .col-xxl-15 {
    width: 50%;
    float: left;
  }

  .astrologer-form {
    text-align: center;
    padding-bottom: 30px;
  }

  .astrologer-form h1 {
    font-size: 22px !important;
    font-weight: 600 !important;
    margin: 20px 5px !important;
    line-height: 30px;
  }

  .rowReverse {
    flex-flow: wrap-reverse !important;
  }

  .rowReverse .input-group {
    width: 100%;
  }

  .contact .send-btn {
    position: absolute;
    margin: 15px 0px;
    top: 100%;
    right: 0;
    text-align: right;
  }

  /* .contact input {
    width: 85% !important;
  } */

  /* .astropage button img {
    width: 18px;
    height: 20px;
    padding: 0;
  } */

  /* .astropage button .dropdown {
    font-size: 15px;
  } */

  .astrologer-form .mobileimgdisplay {
    display: block;
  }

  .astrologer-form .mobileimg2 {
    display: none;
  }

  .astrologer-form .addimg {
    margin: 10px 0;
  }

  .astrologer-form .addimg img {
    width: 100% !important;
  }

  .astrologer-form .addimg .img1 {
    height: 57px;
  }

  .astrologer-form .addimg .img2 {
    margin-left: 0px !important;
  }

  /* .astropage button.pricebtn {
    width: 95% !important;
    padding: 8px 15px !important;
  } */
}

@media (max-width: 550px) {
  .col-xxl-15 {
    width: 100%;
    float: left;
  }

  .socio-left {
    width: 60%;
  }

  .socialicon {
    width: 40%;
  }
}

@media (max-width: 385px) {
  .ripple.ripple-surface.btn img {
    width: 12px;
    height: 12px;
  }
}

@media (max-width: 440px) {
  .astropage .tta {
    width: 25%;
  }

  .astropage .price-cont {
    width: 50%;
  }
}
